/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'

import H1 from 'elements/H1'

import ImgTag from './ImgTag'
import './index.scss'

const ThirdBlock = ({ title, subBlocks }) => {

  return (
    <div className="how-third-block" id="second-block">
      <div className="title">
        <H1 color="primary" className="how-title" bold>{title}</H1>
      </div>
        <div className="flow">
          {subBlocks.map((subBlock, index) => (
            <ImgTag {...subBlock} key={index} isLast={subBlocks.length - 1 === index} />
          ))}
        </div>
    </div>
  )
}

export default ThirdBlock