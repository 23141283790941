/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState, useEffect } from 'react';

import H1 from 'elements/H1'
import Image from 'elements/Image'
import Text from 'elements/Text'

import LinkItem from './LinkItem'
import downArrow from 'images/icon/arrowPrimary.svg'
import './index.scss'

const FourthBlock = ({subBlocks}) => {
  const [src, setSrc] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [len, setLen] = useState(subBlocks?.length);

  const onResize = () => {
    if(window) {
      const vw = window.innerWidth
      if(vw >= 768) setIsMobile(false)
      if(vw < 768) setIsMobile(true)
    }
  }

  useEffect(() => {
    if(window) {
      onResize()
      window.addEventListener('resize', onResize)
    }
    return(() => {
      window.removeEventListener('resize', onResize)
    })
  }, [])

  const next = () => {
    setSrc((src + 1) % len)
  };

  const prev = () => {
    setSrc((src + len - 1) % len)
  };

  if (!isMobile) {
    return (
      <div className="how-fourth-block" >
        <div className="text-section">
            {subBlocks?.map(({title, description}, idx) => {
              return (
                <LinkItem key={idx} title={title} description={description} idx={idx} setSrc={setSrc} src={src}/>)
            })}
        </div>
        <div className="image-section">
          <Image
            src={subBlocks[src]?.image?.file?.url}
            ratio={0.9}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className="how-fourth-block" >
        <div className="image-section">
          <Image
            src={subBlocks[src]?.image?.file?.url}
            ratio={0.9}
          />
          <img src={downArrow} alt="" className="arrow-left" onClick={() => prev()} />
          <img src={downArrow} alt="" className="arrow-right" onClick={() => next()} />
        </div>
        <div className="text-section">
          <H1 size="large-mini-minus" className="mobile-text-title" bold>{subBlocks[src]?.title}</H1>
          <Text 
            size="medium-minus" 
            color="black" 
            family="Univers-LT-Std-45-Light" 
            className="mobile-text-description" 
            family="OpenSans"
          >{subBlocks[src]?.description?.description}
          </Text>
        </div>
      </div>
    )
  }
}

export default FourthBlock