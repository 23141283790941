import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'

import Layout from 'components/Layout'
import FirstBlock from 'routes/how-does-it-work/FirstBlock'
import SecondBlock from 'routes/how-does-it-work/SecondBlock'
import ThirdBlock from 'routes/how-does-it-work/ThirdBlock'
import FourthBlock from 'routes/how-does-it-work/FourthBlock'
// import SEO from 'components/seo'

const HowDoesItWorkPage = () => {
  const [clicked, setClicked] = useState(false);
  const data = useStaticQuery(graphql`
    query HowDoesItWorkPageQuery {
      contentfulPage(title: { eq: "How it works?" }) {
        blocks {
          description {
            description
          }
          title
          button {
            label
            path
          }
          image {
            file {
              url
            }
          }
          subBlocks {
            ... on ContentfulPageBlock {
              title
              description {
                description
              }
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  const blocks = data?.contentfulPage?.blocks || []
  return (
    <Layout isHome={true} showOrder={clicked} closeOrder={() => setClicked(false)}>
      <FirstBlock {...blocks[0]} onClick={() => setClicked(true)} />
      <ThirdBlock {...blocks[1]} />
      <FourthBlock {...blocks[2]} />
      <SecondBlock {...blocks[3]} onClick={() => setClicked(true)} />
    </Layout>
  )
}

export default HowDoesItWorkPage
